.widget-app-container {
  box-sizing: border-box;
  margin-bottom: 12px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;

    &-title {
      font-size: 14px;
      font-weight: 500;
      color: var(--primary-text-color);
    }

    &-action {
      font-size: 14px;
      font-weight: 500;
      color: var(--primary-color);
      cursor: pointer;
    }
  }

  &-body {
    padding: 0px;

    .news-card {
      border: none;
      border-radius: var(--border-radius-60);

      .news-card-content {
        padding: 17px 20px;
      }
    }

    .widget-feature-requests {
      padding: 20px;
    }
  }
}

.widget-feature-requests {
  border-top: 1px solid var(--border-color-55);
  padding-top: 20px;
}

.widget-unread-messages-container,
.widget-menu-button-container {
  border-radius: var(--border-radius-60);
  background-color: var(--background-color);
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 2px 8px;
  margin-bottom: 12px;

  .widget-menu-buttons {
    padding: 10px;
  }

  .widget-menu-previous-feedback {
    border-top: 1px solid var(--border-color-33);
    padding: 10px 20px;

    .widget-menu-all-conversations {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      span {
        font-size: 14px;
        line-height: 32px;
        font-weight: 500;
        font-family: Inter, sans-serif;
        color: var(--primary-text-color);
      }

      &:hover {
        span {
          color: var(--primary-color);
        }
      }
    }
  }
}

.widget-unread-messages-container {
  overflow: hidden;

  .conversation-item:first-of-type {
    border-top-left-radius: var(--border-radius-50);
    border-top-right-radius: var(--border-radius-50);
  }

  .conversation-item:last-of-type {
    margin-bottom: 0px !important;
  }

  .widget-menu-previous-feedback {
    border-top: none;
  }
}
