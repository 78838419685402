.news-details {
  height: 100vh;
  overflow: auto;
  background-color: var(--background-color);
  z-index: 2;
  position: relative;

  .news-details-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;

    .gleap-loading {
      width: 34px;
      height: 34px;
    }

    .gleap-loading div {
      width: 34px;
      height: 34px;
      border-width: 3px;
    }
  }

  .news-card-image {
    width: 100%;
    height: 100%;
    max-height: 160px;
    object-fit: cover;
    background-color: var(--background-color-dark);
    transition: max-height 0.2s ease-out;

    @media only all and (min-width: 420px) {
      max-height: 330px;
    }

    @media (orientation: landscape) and (min-width: 700px) {
      max-height: 530px;
    }

    @media (orientation: portrait) and (min-width: 700px) {
      max-height: 400px;
    }
  }

  .back-button {
    position: fixed;
    top: 8px;
    left: 8px;

    .icon-button {
      background-color: var(--background-color-99);
      border-radius: var(--border-radius-60);

      svg path {
        fill: var(--primary-text-color);
      }

      @media (hover: hover) {
        &:hover {
          background-color: var(--primary-color) !important;

          svg path {
            fill: var(--primary-contrast-color);
          }
        }
      }
    }
  }

  .news-title {
    color: var(--primary-text-color);
    padding: 20px;
    padding-bottom: 0px;
    font-size: 22px;
    line-height: 1.4;
    font-weight: 500;
    animation: menuFadeIn;
    animation-duration: 0.45s;
    max-width: 550px;
    margin: auto;

    @media only screen and (min-width: 660px) {
      padding-top: 40px;
    }
  }

  .news-card-content {
    max-width: 550px;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media only screen and (min-width: 660px) {
      padding-bottom: 60px;
    }

    .news-card-content-meta img {
      width: 25px;
      height: 25px;
      margin-right: 12px;
    }

    &-body {
      > :first-child {
        margin-top: 0px !important;
      }

      p {
        min-height: 14px;
      }

      a,
      p,
      span {
        color: var(--primary-text-color);
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
      }

      strong * {
        font-weight: 600;
      }

      a {
        color: var(--primary-color);
        text-decoration: underline;
      }

      ul {
        padding-left: 15px;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .linktype-button {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 500px;
        object-fit: contain;
      }

      h1,
      h2 {
        margin-top: 30px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 19px;
        line-height: 1.4;
        font-weight: 500;
        color: var(--primary-text-color);

        span {
          font-size: 19px;
          line-height: 1.4;
          font-weight: 500;
          color: var(--primary-text-color);
        }
      }

      h3 {
        margin-top: 25px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 17px;
        line-height: 1.4;
        font-weight: 500;
        color: var(--primary-text-color);

        span {
          font-size: 17px;
          line-height: 1.4;
          font-weight: 500;
          color: var(--primary-text-color);
        }
      }

      iframe {
        width: 100%;
        border: 0px !important;
        height: 300px;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .iframe-wrapper {
        position: relative;
        padding-bottom: 62.5%;
        height: 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      pre {
        background: #0d0d0d;
        border-radius: 0.5rem;
        color: #fff;
        font-family: "JetBrainsMono", monospace;
        padding: 0.75rem 1rem;
        font-size: 14px;
        line-height: 18px;
        max-width: 100%;
        overflow: auto;
        margin-top: 20px;
        margin-bottom: 20px;

        code {
          background: none;
          color: inherit;
          font-size: 0.8rem;
          padding: 0;
          font-family: "JetBrainsMono", monospace;
        }
      }
    }
  }
}