.tab-bar {
  width: 100%;
  height: 80px;
  background-color: var(--background-color);
  box-shadow: 0px 0px 1px 1px rgba($color: #000000, $alpha: 0.05);
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 999;
  animation: fadeInUp;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;

  &-item {
    display: flex;
    flex-direction: column;
    width: 25%;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .icon-container {
      position: relative;
      display: flex;
    }

    &--unread {
      width: 17px;
      height: 17px;
      border-radius: 17px;
      background-color: #da0e07;
      box-shadow: 0px 0px 0px 1.5px var(--background-color);
      position: absolute;
      right: -11px;
      top: -11px;
      color: #fff;
      font-weight: 600;
      font-size: 9px;
      text-align: center;
      line-height: 17px;
    }

    svg {
      height: 18px;
      width: auto;
      margin-bottom: 8px;

      path {
        fill: var(--primary-text-color);
      }
    }

    span {
      font-size: 13px;
      line-height: 16px;
      font-weight: 400;
      color: var(--primary-text-color);
    }

    &.active, &:hover {
      svg {
        opacity: 1.0;
        
        path {
          fill: var(--primary-color);
        }
      }

      span {
        opacity: 1.0;
        color: var(--primary-color);
      }
    }
  }
}
