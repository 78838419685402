.one-to-five-form-item {
  &-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  &-item {
    cursor: pointer;
    padding: 0;
    border: 1px solid var(--primary-color-55);
    background-color: var(--primary-color-07);
    color: var(--primary-color);
    border-radius: var(--border-radius-25);
    text-align: center;
    margin: 3px;
    width: 42px;
    min-width: 42px;
    max-width: 42px;
    line-height: 40px;
    min-height: 42px;
    max-height: 42px;
    font-size: 17px;
    font-weight: 500;

    &:hover,
    &--active {
      background-color: var(--primary-color-22);
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
    }
  }

  &-labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      color: var(--secondary-text-color);
      font-size: 13px;
      font-weight: 500;
    }
  }
}
